/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/global.css'

import { Cache } from 'aws-amplify'
import queryString from 'query-string'

import wrapWithProvider from './wrap-with-provider'

export const wrapRootElement = wrapWithProvider

// TODO: Tidy logging if this is working as expected

export const onClientEntry = () => {
  const params = queryString.parse(window?.location?.search)
  let parsedParams = {}
  let error = false
  // const originalSearch = location.search
  // console.log({originalSearch})

  // console.log(Object.keys(params))
  Object.keys(params).forEach(function (key) {
    // console.log('Before1', { key, value: params[key] })
    params[key] = params[key].replace(/%/i, '') // Seems to be the only fix for now...
    // console.log('Before2', { key, value: params[key] })

    try {
      if(document && key === 'fbclid'){
         const fbclid = `fb.1.${Date.now()}.${params[key]}`
         document.cookie = `_fbc=${fbclid}; expires=${new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`
      }
    } catch (err) {
      console.error(err)
    }

    try {
      // console.log('Try')
      // console.log('Before')
      // console.log('decode', decodeURIComponent(params[key]))
      // console.log('encode', encodeURIComponent(params[key]))
      // console.log('encode2', encodeURIComponent(encodeURIComponent(params[key])))
      parsedParams[key] = decodeURIComponent(params[key])
      parsedParams[key] = encodeURIComponent(parsedParams[key].replace("'", ''))
      // console.log('Success')
    } catch (err) {
      // console.log('Catch', err)
      parsedParams[key] = encodeURIComponent(params[key])
      error = true
    }
    // console.log('After', { key, value: parsedParams[key] })
  })

  const qs =
    '?' +
    Object.keys(parsedParams)
      .map(key => key + '=' + parsedParams[key])
      .join('&')
  // console.log({ error, qs, search: location.search === qs })

  if ((error || window?.location?.search !== qs) && qs !== '?') window.location.search = qs
  // window.location = `/${qs}`

  // Redirect spammy traffic
  if(document?.referrer && document.referrer.includes('loquax')) window.window.location = '/404'
}

export const onRouteUpdate = ({ location }) => {
  const { coupon = null } = queryString.parse(location.search)
  const firstPath = location.pathname.split('/')[1]

  if (!['account', 'admin'].includes(firstPath) && coupon) Cache.setItem('coupon', coupon)

  checkIfNeedsRefresh()
}

const checkIfNeedsRefresh = () => {
  fetch('/page-data/app-data.json')
    .then(res => res.json())
    .then(({ webpackCompilationHash }) => {
      console.error(`Build version: ${webpackCompilationHash}`)
      const hashFromCache = Cache.getItem('webpackCompilationHash')

      if (hashFromCache) {
        if (hashFromCache !== webpackCompilationHash) {
          window.location.reload(true)
        }
      }

      Cache.setItem('webpackCompilationHash', webpackCompilationHash)
    })
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true)
}
