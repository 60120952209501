export enum ActionType {
  UPDATE_PLANS_PENDING = 'updatePlansPending',
  UPDATE_PLANS = 'updatePlan',
  UPDATE_PLANS_FAIL = 'updatePlanFail',
  UPDATE_ORDERS = 'updateOrders',
  PAUSE_PLAN_SUCCESS = 'pausePlanSuccess',
  PAUSE_PLAN_FAIL = 'pausePlanFail',
  CANCEL_PLAN_SUCCESS = 'cancelPlanSuccess',
  CANCEL_PLAN_FAIL = 'cancelPlanFail',
  FETCH_PLAN_SCHEDULE = 'fetchPlanSchedule',
  FAILED_PLAN_SCHEDULE = 'failedPlanSchedule',
  POST_RESCHEDULE_PLAN_SUCCESS = 'postReschedulePlanSuccess',
  POST_RESCHEDULE_PLAN_FAIL = 'postReschedulePlanFail',
  RESET_ERROR_MESSAGE = 'resetAccountPlanErrorMessage',
  RESET = 'resetPlansAccount',
  CANCEL_PAUSE_PLAN_REASON = 'cancelPausePlanReason'
}

export interface PlanType {
  subscriptionId: any
  addons: []
  address: {
    address1: string
    address2: string
    city: string
    county: string
    postcode: string
  }
  coupons: []
  dogNames: string
  dogs: []
  targetPaymentDate: number
  nextDelivery: string
  nextPayment: string
  plan: string
  price: number
  quantity: number
  recipes: []
  status: string
  redZone?: boolean
}

export interface OrderType {
  amount_adjusted: number
  amount_paid: number
  billing_address: any
  created_at: number
  created_by: string
  currency_code: string
  customer_id: string
  deleted: boolean
  discount: number
  document_number: string
  exchange_rate: number
  id: string
  invoice_id: string
  is_gifted: boolean
  is_resent: boolean
  line_item_discounts: []
  linked_credit_notes: []
  object: string
  order_date: number
  order_line_items: []
  order_type: string
  paid_on: number
  payment_status: string
  price_type: string
  refundable_credits: number
  refundable_credits_issued: number
  resent_orders: []
  resource_version: number
  rounding_adjustment: number
  shipDate: string
  shipping_address: {
    city: string
    country: string
    first_name: string
    last_name: string
    line1: string
    object: string
    phone: string
    validation_status: string
    zip: string
  }
  shipping_date: number
  status: string
  sub_total: number
  subscription_id: string
  tax: number
  total: number
  updated_at: string
}

interface UpdatePlansPending {
  type: ActionType.UPDATE_PLANS_PENDING
}

interface UpdatePlansAction {
  type: ActionType.UPDATE_PLANS
  payload: PlanType[]
}

interface UpdatePlansFailAction {
  type: ActionType.UPDATE_PLANS_FAIL
  payload: any
}

interface UpdateOrdersAction {
  type: ActionType.UPDATE_ORDERS
  payload: OrderType[]
}

interface PausePlanSuccessAction {
  type: ActionType.PAUSE_PLAN_SUCCESS
}

interface CancelPlanSuccessAction {
  type: ActionType.CANCEL_PLAN_SUCCESS
}

interface PausePlanFailAction {
  type: ActionType.PAUSE_PLAN_FAIL
  payload: any
}

interface CancelPlanFailAction {
  type: ActionType.CANCEL_PLAN_FAIL
  payload: any
}

interface CancelPausePlanReasonAction {
  type: ActionType.CANCEL_PAUSE_PLAN_REASON
  payload: any
}

interface FetchPlanScheduleAction {
  type: ActionType.FETCH_PLAN_SCHEDULE
  payload: Array<string>
}

interface FailedToFetchPlanScheduleAction {
  type: ActionType.FAILED_PLAN_SCHEDULE
  payload: any
}

interface PostReschedulePlanSuccessAction {
  type: ActionType.POST_RESCHEDULE_PLAN_SUCCESS
}

interface PostReschedulePlanFailAction {
  type: ActionType.POST_RESCHEDULE_PLAN_FAIL
  payload: any
}

interface ResetErrorMessageAction {
  type: ActionType.RESET_ERROR_MESSAGE
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action =
  | UpdatePlansPending
  | UpdatePlansAction
  | UpdateOrdersAction
  | ResetErrorMessageAction
  | ResetAction
  | PausePlanSuccessAction
  | CancelPlanSuccessAction
  | PausePlanFailAction
  | CancelPlanFailAction
  | FetchPlanScheduleAction
  | PostReschedulePlanSuccessAction
  | PostReschedulePlanFailAction
  | FailedToFetchPlanScheduleAction
  | UpdatePlansFailAction
  | CancelPausePlanReasonAction
